<template>
<div>
  <Modal
    v-model="visible"
    title="回访记录"
    width="800"
    :styles="{ top: '20px' }"
    :mask-closable="false"
    @on-cancel="returnPath"
  >
    <div class="modal-body">
      <div class="signin-toolbar">
        <ul
        class="list-group list-group-unbordered">
          <li class="list-group-item">
            <b>姓名</b> <span class="">{{ reviewrecord.User }}</span>
          </li>
          <li class="list-group-item">
            <b>项目名称</b> <span class="">{{ reviewrecord.Project }}</span>
          </li>
          <li class="list-group-item">
            <b>回访类型</b>
            <span class="">{{ type }}</span>
          </li>
          <li class="list-group-item">
            <b>评分</b> <span class="">{{ reviewrecord.Score }}</span>
          </li>
          <li class="list-group-item">
            <b>备注</b> <span class="">{{ reviewrecord.Remark }}</span>
          </li>
          <li class="list-group-item">
            <b>回访时间</b> <span class="">{{ this.$moment(reviewrecord.Time).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">确定</Button>
      <!-- <Button type="primary" size="large" @click="toggleReviewForm">添加新的回访</Button> -->
    <div style="clear: both;"></div>
    </div>
  </Modal>
    <!-- <userReviewRecordsAdd :data="UserReview" :show="showReviewForm" @postAdd="hideReviewForm"></userReviewRecordsAdd> -->
</div>
</template>

<script>
// import userReviewRecordsAdd from './UserReviewRecordsAdd'

export default {
  name: 'User-ReviewRecords',
  props: ["UserReviewDetail", "show"],
  components: {
    // userReviewRecordsAdd
  },
  data() {
    return {
      visible: false,
      reviewrecord: {
        UserId: "",
        User: "",
        ProjectId: "",
        Project: "",
        Type: "",
        Score: "",
        Remark: "",
        Time: ""
      },
      type: '',
      showReviewForm: false,
      UserReview: [],
      project: {},
    }
  },
  mounted() {
  },
  watch: {
    show: function (newVal) {
      this.visible = newVal;
    },
    UserReviewDetail: function (newVal) {
      if (newVal) {
        this.reviewrecord = newVal
        this.type = newVal.Type
        // console.log(this.type)
        if (this.type === 0) {
          return this.type = '无'
        } else if (this.type === 1) {
          return this.type = '电话回访'
        } else {
          this.type = '未知'
        }
      }
    },
  },
  methods: {
    toggleReviewForm() {
      this.showReviewForm = true
    },
    hideReviewForm() {
      this.showReviewForm = false
    },
    returnPath() {
      // this.visible = false;
      this.$emit("close", null);
      this.$Modal.remove();
      // this.reviewrecord=[];
    },
  }
}
</script>
